export const LOCAL_STORAGE = {
  ORI_ADMIN: "dashboardUser",
  VERSION: "dashboardVersion",
  LIVE_CHATS_UNREAD_MESSAGE_COUNT: "liveChatsUnreadMessageCount",
  UNSEEN_LIVE_CHATS_STALL_COUNT: "unseenLiveChatStallCount",
  BIFURCATION_DATA: "bifurcationData",
  THEME: "dashboardTheme"
}

export const COOKIES = {
  TOKEN: "token"
}

export const METHOD_TYPES = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
}

export const ACTION_TYPES = {
  UPDATE_STATE: "UPDATE_STATE",
  LOAD_MORE_UTTERANCES: "LOAD_MORE_UTTERANCES",
}

export const CURRENCY = {
  RUPEES: "rupees",
  DOLLAR: "dollar",
}

export const FORM_ITEM_TYPE = {
  INPUT: "input",
  INPUT_NUMBER: "inputNumber",
  INPUT_STRING: "inputString",
  MULTIPLE_INPUT: "multipleInput",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  RADIO_GROUP_WITH_MULTI_OPTIONS: "radioGroupWithMultiOptions",
  SELECT: "select",
}

export const NLP_SERVICE_NAME = {
  LUIS: "luis",
  RASA: "rasa",
}

export const WF_DIAGRAM = {
  DEFAULT_EDGE: "defaultEdge",
  BLOCK_NODE: "blockNode", //BN
  CONDITIONAL_NODE: "conditionalNode", //CN
  ACTION_NODE: "actionNode", //AN
  TRIGGER_NODE: "triggerNode", //TN
  WORKFLOW_NODE: "workflowNode", //WN
  WN_SOURCE_HANDLE: "__WN-SH__",
  TIMER_NODE: "timerNode", //TIN
  TIN_TARGET_HANDLE: "__TIN-TH__",
  BN_SOURCE_HANDLE: "__BN-SH__",
  BN_TARGET_HANDLE: "__BN-TH__",
  CN_TARGET_HANDLE: "__CN-TH__",
  AN_TARGET_HANDLE: "__AN_TH__",
  AN_SOURCE_HANDLE: "__AN_SH__",
  TN_SOURCE_HANDLE: "__TN_SH__",
  WN_TARGET_HANDLE: "__WN_TH__",
  FEATURE_NODE: "featureNode", //FN
  LIVE_CHAT_NODE: "livechatNode", //LCN
  CATALOGUE_NODE: "catalogueNode", //CATN
  CUSTOM_WORKFLOW_NODE: "customWorkflowNode", //CWN
  LIVECHAT_NODE_TARGET_HANDLE: "__LCN-TH__",
  CATALOGUE_NODE_TARGET_HANDLE: "__CATN-TH__",
  CUSTOM_WORKFLOW_NODE_TARGET_HANDLE: "__CWN-TH__",
}

export const GRANULARITY_OPTIONS = [
  { value: "hour", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
]

//Report Configuration
export const RC_FREQUENCY = [
  { value: "hourly", name: "Hourly" },
  { value: "daily", name: "Daily" },
  { value: "weekly", name: "Weekly" },
  { value: "monthly", name: "Monthly" },
]

export const RC_WEEKDAY = [
  { value: 0, name: "Sunday" },
  { value: 1, name: "Monday" },
  { value: 2, name: "Tuesday" },
  { value: 3, name: "Wednesday" },
  { value: 4, name: "Thursday" },
  { value: 5, name: "Friday" },
  { value: 6, name: "Saturday" },
]

export const RC_FLAG = [
  { value: "makeZip", label: "Make Zip" },
  { value: "transferFile", label: "Tranfer File" },
]

export const RC_DATE = [
  { value: 1, name: "1" },
  { value: 2, name: "2" },
  { value: 3, name: "3" },
  { value: 4, name: "4" },
  { value: 5, name: "5" },
  { value: 6, name: "6" },
  { value: 7, name: "7" },
  { value: 8, name: "8" },
  { value: 9, name: "9" },
  { value: 10, name: "10" },
  { value: 11, name: "11" },
  { value: 12, name: "12" },
  { value: 13, name: "13" },
  { value: 14, name: "14" },
  { value: 15, name: "15" },
  { value: 16, name: "16" },
  { value: 17, name: "17" },
  { value: 18, name: "18" },
  { value: 19, name: "19" },
  { value: 20, name: "20" },
  { value: 21, name: "21" },
  { value: 22, name: "22" },
  { value: 23, name: "23" },
  { value: 24, name: "24" },
  { value: 25, name: "25" },
  { value: 26, name: "26" },
  { value: 27, name: "27" },
  { value: 28, name: "28" },
  { value: 29, name: "29" },
  { value: 30, name: "30" },
  { value: 31, name: "31" },
]

export const TIME_CASE = [
  { value: 'second', label: 'Second' },
  { value: 'minute', label: 'Minute' },
  { value: "hour", label: "Hour" },
]

export const DEPLOYED_ENV_INTENT = [
  { label: "Dev", value: "dev" },
  { label: "UAT", value: "uat" },
  { label: "Prod", value: "prod" },
]

export const DEPLOYED_ENV_FAQ = [
  { label: "Dev", value: "dev" },
  { label: "Prod", value: "prod" },
]

export const PASS_CASES = [
  { label: "All", value: "" },
  {
    label: "Passed",
    value: "passed",
    tooltipText: "Filters chat where no issues (marked as red) were found.",
  },
  {
    label: "Failed",
    value: "failed",
    tooltipText:
      "Filters chat where alteast one issue (marked as red) was found.",
  },
]
export const LANGUAGE_VARIATION = {
  en:"English",
  hindi: "Hindi",
  ar: "Arabic"
}
